<template>
  <div>
    <el-card class="box-card">
      <el-row slot="header" align="middle" justify="space-between" type="flex">
        <b>Заявки</b>

      </el-row>
      <el-tabs type="card" value="alarm" @tab-click="handleClick">
        <el-tab-pane label="Виджет с сайта" name="alarm"></el-tab-pane>
        <el-tab-pane label="Перезвоните мне" name="callback"></el-tab-pane>
        <el-tab-pane label="Заявки на подключение" name="connect"></el-tab-pane>
      </el-tabs>
      <el-table v-loading="loading" :data="subjects" border style="width: 100%">
        <el-table-column label="ФИО клиента / Название организации">
          <template
              slot-scope="scope"
          >{{ scope.row.lname }} {{ scope.row.name }} {{ scope.row.sname }}
          </template>
        </el-table-column>
        <el-table-column label="Мобильный" prop="phone">
          <template slot-scope="scope">
            <input
                v-mask="'+# (###) ###-##-##'"
                :value="scope.row.phone"
                class="input-read"
                disabled="disabled"
                tabindex="-1"
            />
          </template>
        </el-table-column>
        <el-table-column label="Статус заявки" prop="status">
          <template slot-scope="scope">
            <el-button type="primary" style="width: 100%" v-if="scope.row.is_processed == 0">Новая</el-button>
            <el-button type="info" style="width: 100%" v-if="scope.row.is_processed == 1">Обработано</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Действия">
          <template slot-scope="scope">
<!--                v-if="scope.row.is_processed == 0"-->
            <table-action-column
                :document-id="scope.row.id"
                :show-handle="true"
                :show-edit="false"
                :show-remove="false"
                :show-restore="false"
                :show-view="false"
                module-code="subjects"
                @remove="remove(scope)"
                @restore="revert(scope)"
            ></table-action-column>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :page-size="page.size"
          :total="page.total"
          :current-page="page.current_page"
          layout="prev, pager, next"
          @current-change="paginate"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import {logger} from "@/modules/logger";
import TableActionColumn from "@/components/TableActionColumn";
export default {
  name: "ClientList",
  components: {
    TableActionColumn
  },
  data() {
    return {
      orderType: 'alarm',
      subjects: [],
      page: {
        offset: 1,
        size: 15,
        total: 0
      },
      params: {
        filter: null
      },
      loading: false
    };
  },
  async created() {

    try {
      await this.getSubjects();
    } catch (e) {
      if (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
      }
      logger.error(e);
    }

  },
  watch: {
    $route(to) {
      this.page.offset = this.page.size * (to.params.page - 1);
      this.params = {
        filter: to.query.filter,
      };
      this.getSubjects();
    }
  },
  methods: {
    handleClick(item) {
      this.orderType = item.name;
      this.getSubjects()
    },
    async paginate(event) {
      this.page.offset = event;
      await this.getSubjects();
    },
    async getSubjects() {
      var p = ""
      if (this.page.offset)
        p = "?page=" + this.page.offset
      this.subjects = []
      this.loading = true;
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/requests/${this.orderType.toString()}/get${p}`,
            {
              params: {
                offset: this.page.offset,
                limit: this.page.size,
                filter: this.params.filter,
              }
            }
        );

        const data = result.data.data.map(item => {
          return item;
        });

        if (data) {
          this.subjects = data;
        }
        this.page.size = result.data.per_page;
        this.page.current_page = result.data.current_page;
        this.page.total = result.data.total;
      } catch (e) {
        throw e;
      }
      this.loading = false;
    },
    async remove(scope) {
      try {
        // const index = scope.$index;
        const id = scope.row.id;
        await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/requests/${this.orderType.toString()}/process`,
            {
              id: id
            }
        );
        // this.requests.splice(index, 1);
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
        logger.error(e);
      }
      this.subjects[scope.$index].is_processed = 1
    },
    async revert(scope) {
      try {
        // const index = scope.$index;
        const id = scope.row.id;
        await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/requests/${this.orderType.toString()}/revert`,
            {
              id: id
            }
        );
        // this.requests.splice(index, 1);
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
        logger.error(e);
      }
      this.subjects[scope.$index].is_processed = 0
    },
  }
};
</script>

<style scoped></style>
