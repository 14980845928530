<template>
  <div>
    <dashboard-card :texts="[{title: 'чекапы ', value: infos.services_top.checkups },
                            {title: 'записи на услуги клиник ', value: infos.services_top.service_requests },
                            {title: 'записи за консультации к врачам ', value: infos.services_top.doctor_requests },
                            ]"
                    span="20" title=" ТОП продаваемых услуг"/>
    <dashboard-card card-style="default" :texts="[{title: '', value: infos.more_that_one_purchase }]"
                    span="12" title="Количество пользователей, совершивших более одной покупки в приложении"/>
    <dashboard-card :texts="[{title: '(в Тенге)', value: infos.average_purchase}]"
                    card-style="default" span="8" title="Средний чек, покупаемых услуг"/>
    <dashboard-card :texts="[{title: 'месяц', value: infos.total_visitors.month },{title: 'год', value: infos.total_visitors.year}]"
                    card-style="primary"
                    span="10"
                    title="Общее количество посещений"/>
    <dashboard-card
        card-style="primary"
        :texts="[{title: 'пользователи', value: infos.registered_users.users},{title: 'врачи', value: infos.registered_users.doctors},{title: 'клиники', value: infos.registered_users.clinics}]"
        span="10"
        title="Количество зарегистрированных пользователей"/>
    <dashboard-card card-style="success" :texts="[{title: 'м', value: infos.gender.male},{title: 'ж', value: infos.gender.female}]"
                    span="5" title="Пользователи разрезе пола"/>
    <dashboard-card card-style="success" :texts="[{title: '', value: infos.average_age }]"
                    span="5" title="Средний возраст пользователей"/>
    <dashboard-card :texts="[{title: 'месяц ', value: infos.cancellations.month},
                            {title: 'год ', value: infos.cancellations.year}]"
                    span="10" title="Количество возвратов" card-style="danger"/>

  </div>
</template>

<script>
import {logger} from "@/modules/logger";
import DashboardCard from "@/components/DashboardCard";

export default {
  name: "AccountList",
  components: {DashboardCard},
  data() {
    return {
      infos: {},
      page: {
        offset: 0,
        size: 20,
        total: 0
      },
      params: {
        dep_id: null
      },
      loading: false
    };
  },
  async created() {
    this.loading = true;
    try {
      this.setParams();
      await this.getInfo();
    } catch (e) {
      this.$message.error({
        showClose: true,
        message: this.$getErrorText(e),
        duration: 7500
      });
      logger.error(e);
    }
    this.loading = false;
  },
  watch: {
    $route(to) {
      this.page.offset = this.page.size * (to.params.page - 1);
      this.params = {
        dep_id: to.query.dep_id
      };
      this.getInfo();
    }
  },
  methods: {
    async paginate(event) {
      this.$router.push({
        params: {page: event},
        query: {
          dep_id: this.params.dep_id
        }
      });
    },
    setParams() {
      this.page.offset = this.page.size * (this.$route.params.page - 1);
      this.params = {
        dep_id: this.$route.query.dep_id ? this.$route.query.dep_id * 1 : null
      };
    },
    async filterTerminals() {
      this.$router.push({
        params: {page: this.$route.params.page},
        query: {
          dep_id: this.params.dep_id
        }
      });
    },
    async getInfo() {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await this.$http.post("api/admin/dashboard/get");
        this.infos = result.data[0];
      } catch (e) {
        throw e;
      }
    }
  }
};
</script>

<style></style>
