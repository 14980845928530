<template>
  <div>
    <v-popup v-show="create" :title="list.id != null ? 'Редактировать отзыв' : 'Добавить отзыв'"
             @closePopup="closePopup">
      <el-form ref="form" v-loading="loading" :model="list" :rules="rules" label-position="left" label-width="auto">
        <el-form-item label="Автор" prop="name">
          <el-input v-model="list.author" placeholder="автор"></el-input>
        </el-form-item>
        <el-form-item label="Автор на казахском" prop="name">
          <el-input v-model="list.author_kz" placeholder="автор"></el-input>
        </el-form-item>
        <el-form-item label="Автор на английском" prop="name">
          <el-input v-model="list.author_en" placeholder="автор"></el-input>
        </el-form-item>
        <el-form-item label="Заголовок" prop="name">
          <el-input v-model="list.title" placeholder="заголовок"></el-input>
        </el-form-item>
        <el-form-item label="Заголовок на казахском" prop="name">
          <el-input v-model="list.title_kz" placeholder="заголовок"></el-input>
        </el-form-item>
        <el-form-item label="Заголовок на английском" prop="name">
          <el-input v-model="list.title_en" placeholder="заголовок"></el-input>
        </el-form-item>
        <el-form-item label="Текст" prop="name">
          <el-input v-model="list.text" autosize placeholder="Текст" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="Текст на казахском" prop="name">
          <el-input v-model="list.text_kz" autosize placeholder="Текст" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="Текст на английском" prop="name">
          <el-input v-model="list.text_en" autosize placeholder="Текст" type="textarea"></el-input>
        </el-form-item>
        <el-form-item v-show="!list.id" label="Аватар" prop="name">
          <input id="files" ref="files" multiple type="file" v-on:change="handleFilesUpload()"/>
          <!--          <el-input v-model="list.image" placeholder="image" type="file" ref="myFile" accept=".jpg, .jpeg, .png" v-on:change="handleFilesUpload()" ></el-input>-->
        </el-form-item>
        <el-button type="primary" @click="onSubmit">Сохранить</el-button>
        <go-back @closePopup="closePopup"></go-back>
      </el-form>
    </v-popup>
    <el-card class="box-card">
      <el-row slot="header" align="middle" justify="space-between" type="flex">
        <b>Отзывы</b>
        <div class="el-button el-button--primary el-button--small"
             @click="create=!create">
          Добавить
        </div>
      </el-row>
      <el-table v-loading="loading" :data="logs" border style="width: 100%">
        <el-table-column label="#">
          <template slot-scope="scope">{{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column label="Автор" prop="img">
          <template slot-scope="scope">{{ scope.row.author }}</template>
        </el-table-column>
        <el-table-column label="Автор на казахском" prop="img">
          <template slot-scope="scope">{{ scope.row.author_kz }}</template>
        </el-table-column>
        <el-table-column label="Автор на английском" prop="img">
          <template slot-scope="scope">{{ scope.row.author_en }}</template>
        </el-table-column>
        <el-table-column label="Изображение" prop="img">
          <template slot-scope="scope"><img :src="scope.row.image" width="50"/></template>
        </el-table-column>
        <el-table-column label="Заголовок">
          <template slot-scope="scope">{{ scope.row.title }}</template>
        </el-table-column>
        <el-table-column label="Заголовок на казахском">
          <template slot-scope="scope">{{ scope.row.title_kz }}</template>
        </el-table-column>
        <el-table-column label="Заголовок на английском">
          <template slot-scope="scope">{{ scope.row.title_en }}</template>
        </el-table-column>
        <el-table-column label="Текст отзыва">
          <template slot-scope="scope">{{ scope.row.text }}</template>
        </el-table-column>
        <el-table-column label="Текст отзыва на казахском">
          <template slot-scope="scope">{{ scope.row.text_kz }}</template>
        </el-table-column>
        <el-table-column label="Текст отзыва на английском">
          <template slot-scope="scope">{{ scope.row.text_en }}</template>
        </el-table-column>
        <el-table-column label="Действия">
          <template slot-scope="scope">
            <table-action-column
                :show-remove="true"
                module-code="configs"
                @edit="edit(scope)"
                @remove="remove(scope)"
                @view="view(scope.row.id)"
            ></table-action-column>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
          :visible.sync="dialogVisible"
          title=""
          width="70%"
      >
        <span slot="footer" class="dialog-footer">
        </span>
      </el-dialog>
      <el-pagination
          :page-size="page.size"
          :total="page.total"
          :current-page="page.current_page"
          layout="prev, pager, next"
          @current-change="paginate"
      ></el-pagination>
    </el-card>
    <!--<client-files :document-id="selectedClientId"></client-files>-->
  </div>
</template>

<script>
import {logger} from "@/modules/logger";
import TableActionColumn from "@/components/TableActionColumn";
import VPopup from "@/components/PopUp";

export default {
  name: "ClientList",
  components: {
    VPopup,
    TableActionColumn
  },
  data() {
    return {
      logs: [],
      list: {
        id: null,
        title: null,
        text: null,
        author: null,
        title_en: null,
        text_en: null,
        author_en: null,
        title_kz: null,
        text_kz: null,
        author_kz: null,
        image: null
      },
      create: false,
      isCreateForm: false,
      selectedSubjectImage: null,
      files: [],
      page: {
        offset: 1,
        size: 15,
        total: 0
      },
      loading: false,
      dialogVisible: false,
      params: {
        filter: null
      },
      rules: {
        title: {required: true, message: "Обязательное поле", trigger: "blur"},
        author: {required: true, message: "Обязательное поле", trigger: "blur"},
        text: {required: true, message: "Обязательное поле", trigger: "blur"}
      }
    };
  },
  watch: {
    $route(to) {
      this.page.offset = this.page.size * (to.params.page - 1);
      this.params = {
        filter: to.query.filter,
      };
      this.getlogs();
    }
  },
  async created() {
    this.loading = true;
    try {
      await this.getlogs();
    } catch (e) {
      if (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
      }
      logger.error(e);
    }
    this.loading = false;
  },
  methods: {
    closePopup() {
      this.create = !this.create;
      this.list = {
        id: null,
        title: null,
        text: null,
        author: null,
        title_en: null,
        text_en: null,
        author_en: null,
        title_kz: null,
        text_kz: null,
        author_kz: null,
        image: null
      }
    },
    async onSubmit() {
      try {
        await this.$refs.form.validate();
        let addUrl = `${process.env.VUE_APP_API_URL}/admin/feedback/store`;
        let updateUrl = `${process.env.VUE_APP_API_URL}/admin/feedback/update`;
        let url = this.list.id ? updateUrl : addUrl;
        let data = undefined;
        if (this.list.id) {
          url = updateUrl;
          data = {
            id: this.list.id,
            title: this.list.title,
            text: this.list.text,
            author: this.list.author,
            title_en: this.list.title_en,
            text_en: this.list.text_en,
            author_en: this.list.author_en,
            title_kz: this.list.title_kz,
            text_kz: this.list.text_kz,
            author_kz: this.list.author_kz
          }
        } else {
          data = new FormData();
          data.append('title', this.list.title);
          data.append('text', this.list.text);
          data.append('author', this.list.author);
          data.append('title_en', this.list.title_en);
          data.append('text_en', this.list.text_en);
          data.append('author_en', this.list.author_en);
          data.append('title_kz', this.list.title_kz);
          data.append('text_kz', this.list.text_kz);
          data.append('author_kz', this.list.author_kz);
          data.append('image', this.files[0]);
        }
        await this.$http.post(url, data, this.list.id ? null : {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (this.list.index != null) {
          this.logs.splice(this.list.index, 1);
          this.logs.push(this.list, this.list.index)
        } else {
          this.logs.push(this.list)
        }

        this.$message.success("Успешно");
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
      }
      this.create = !this.create;
      this.list = {
        id: null,
        title: null
      }
      await this.getlogs();
    },
    async remove(scope) {
      try {
        const index = scope.$index;
        const id = scope.row.id;
        await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/feedback/archive`,
            {
              id: id
            }
        );
        this.logs.splice(index, 1);
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
        logger.error(e);
      }
    },
    edit(scope) {
      this.create = true;
      this.list = {
        index: scope.$index,
        id: scope.row.id,
        title: scope.row.title,
        text: scope.row.text,
        author: scope.row.author,
        title_en: scope.row.title_en,
        text_en: scope.row.text_en,
        author_en: scope.row.author_en,
        title_kz: scope.row.title_kz,
        text_kz: scope.row.text_kz,
        author_kz: scope.row.author_kz,
        image: scope.row.image
      }
    },
    async paginate(event) {
      this.page.offset = event;
      await this.getlogs();
    },
    async getlogs() {
      // eslint-disable-next-line no-useless-catch
      var p = ''
      if (this.page.offset) {
        p = '?page=' + this.page.offset
      }

      // eslint-disable-next-line no-useless-catch
      try {
        const result = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/feedback/get${p}`,
            {
              params: this.params.filter ? {
                offset: this.page.offset,
                limit: this.page.size,
                filter: this.params.filter
              } : {
                offset: this.page.offset,
                limit: this.page.size,
              }
            }
        );
        const data = result.data.data.map(item => {
          return item;
        });
        if (data) {
          this.logs = data;
        }
        this.page.size = result.data.per_page;
        this.page.current_page = result.data.current_page;
        this.page.total = result.data.total;
      } catch (e) {
        throw e;
      }
    },
    handleFilesUpload() {
      let uploadedFiles = this.$refs.files.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }
    },

    openDialog(id) {
      this.dialogVisible = true
      if (id) {
        this.isCreateForm = true
        this.selectedSubjectImage = this.logs.filter(el => el.id == id)[0].img
      } else {
        this.isCreateForm = false
        this.selectedSubject = null
      }
    }
  },

};
</script>

<style>
.v-popup {
  height: 100%;
  overflow: auto;
}
.v-popup__header{
  margin-top: 1rem;
}
.tooltip--custom {
  max-width: 120px !important;
}
</style>
