<template>
  <div>
    <el-col :span="span">
      <el-card :class="cardStyle" class="dashboard" style="border-radius: 8px">
        <h4>{{ title }}</h4>
        <div class="dashboard__body">
          <h3>
        <span v-for="(text,index) in texts" :key="text.text">
          <span v-if="index!=0">/ </span>{{ text.title }}
        </span>
          </h3>
          <h2>
        <span v-for="(text,index) in texts" :key="text.text">
          <span v-if="index!=0">/ </span>{{ text.value }}
        </span>
          </h2>
        </div>
      </el-card>
    </el-col>
  </div>
</template>

<script>
export default {
  props: {
    title: {String},
    cardStyle: {String},
    texts: {},
    span: {
      Number,
      default: 24
    }
  },
  name: "DashboardCard"
}
</script>

<style lang="scss" scoped>
h2, h1, h3, h4 {
  text-align: center;
  margin: 10px;
}

.dashboard {
  margin: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}
.primary {
  background-color: #3a8ee6!important;
  color: white;
}

.success {
  background-color: #85ce61!important;
  color: white;
}
.danger{
  background-color: #dd6161;
  color: white;
}
.default{
  background-color: gainsboro;
  color: black;
}
</style>
